<template>
  <vs-popup
    :active.sync="isOpen"
    button-close-hidden
    fullscreen
    title="DN"
    ref="dialog"
  >
    <div class="flex flex-col" :style="{ minHeight: contentHeight }">
      <div class="flex flex-row">
        <div
          class="p-4 w-1/2 overflow-y-auto border-r border-l-0 border-t-0 border-b-0 border-solid border-grey-light"
          :style="{ minHeight: rowHeight, maxHeight: rowHeight }"
        >
          <debit-note
            v-if="!isLoading"
            :supplierCode="SupplierCode"
            :supplierID="SupplierID"
            :isOpen="isOpen"
            @resetIsLoading="resetIsLoading"
          />
        </div>
        <div
          class="p-4 w-1/2 overflow-y-auto"
          :style="{ minHeight: rowHeight, maxHeight: rowHeight }"
        >
          <selected-proposals
            :proposal-data="selectedProposal"
            :currencyCode="currencyCode"
            :isOpen="isOpen"
            :unpaidValues="unpaidValues"
          />
        </div>
      </div>
      <vs-divider />
      <div class="flex flex-row justify-between w-full gap-4">
        <total-info
          :title="'Total Paid Now Selected Proposal'"
          :value="
            priceFormatWithMinus(
              this.$store.state.clearingDN.totalSelectedPaidNows,
              !currencyCode ? '' : currencyCode
            )
          "
        />
        <total-info
          :title="'Total Selected DN'"
          :value="
            priceFormatWithMinus(
              this.$store.state.clearingDN.totalSelectedDebitNotesValue,
              !currencyCode ? '' : currencyCode
            )
          "
        />
        <total-info
          :title="'Total Proposal - Total DN'"
          :value="calculateProposalMinusDN(!currencyCode ? '' : currencyCode)"
        />
      </div>
      <div class="flex flex-row justify-end gap-4">
        <vs-button class="sticky" color="danger" @click="closeModal(false)"
          >Cancel</vs-button
        >
        <vs-button class="sticky" @click="handleClearing">Clearing</vs-button>
      </div>
    </div>
  </vs-popup>
</template>

<style scoped></style>

<script>
import DebitNote from "./debit-note.vue";
import selectedProposal from "./selected-proposal.vue";
import TotalInfo from "./totalInfo.vue";
import moment from "moment";

export default {
  mounted() {
    this.contentHeight = `calc(${window.innerHeight}px - 80px)`;
    this.rowHeight = `calc(${window.innerHeight}px - 280px)`;
  },
  created() {
    this.$store.watch(
      (state) => state.clearingDN.selectedDebitNotes,
      (newValue) => {
        const temp = [...newValue];
        let total = temp.reduce((acc, curr) => {
          const remaining = curr.value;
          return acc + remaining;
        }, 0);

        this.$store.commit("clearingDN/setTotalSelectedDebitNotesValue", total);
      }
    );

    this.$store.watch(
      (state) => state.clearingDN.proposalPaidNows,
      (newValue) => {
        const temp = [...newValue];
        const temp2 = [...this.$store.state.clearingDN.selectedDebitNotes];

        const total = temp.reduce((acc, curr) => {
          return acc + curr.value;
        }, 0);
        this.$store.commit("clearingDN/setTotalSelectedPaidNows", total);

        if (temp.length <= this.selectedProposal.length) {
          if (temp.length === this.selectedProposal.length) {
            this.isInitialized = true;
          }
          return;
        }

        if (!this.isInitialized) {
          return;
        }

        let total2 = temp2.reduce((acc, curr) => {
          const remaining = curr.value;
          return acc + remaining;
        }, 0);

        if (total2 > total) {
          total2 = total;
        }
        this.$store.commit("clearingDN/setTotalSelectedDebitNotesValue", total);
      }
    );
  },
  components: {
    "selected-proposals": selectedProposal,
    "debit-note": DebitNote,
    "total-info": TotalInfo,
  },
  props: {
    isOpen: Boolean,
    selectedProposal: Array,
  },
  data() {
    return {
      contentHeight: "",
      rowHeight: "",
      isInitialized: false,
      isLoading: true,
      currencyCode: "",
      SupplierCode: "",
      SupplierID: 0,
      unpaidValues: [],
    };
  },
  methods: {
    closeModal(isReload) {
      // this.isOpen = false;
      if (isReload) {
        this.$emit("closeModalWithReload", false);
      } else {
        this.$emit("closeModal", false);
      }
      this.$store.commit("clearingDN/clearState");
    },
    calculateProposalMinusDN(currencyCode) {
      let dif =
        this.$store.state.clearingDN.totalSelectedPaidNows -
        this.$store.state.clearingDN.totalSelectedDebitNotesValue;

      if (dif < 0) {
        dif = 0;
      }
      this.$store.commit("clearingDN/setTotalDebitNotesMinusPaidNows", dif);
      return this.priceFormatWithMinus(
        this.$store.state.clearingDN.totalDebitNotesMinusPaidNows,
        currencyCode
      );
    },
    priceFormatWithMinus(amount, currencyCode) {
      const newAmount = amount
        .toString()
        .replace(/[^.\d-]/g, "")
        .toString();
      // console.log(amount, newAmount)
      amount = parseFloat(newAmount).toFixed(2);

      return `${amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (${currencyCode})`;
    },
    async updatePriceValue(newValue, id) {
      const data = {
        id,
        paid_value: newValue,
      };
      return this.$http.put(
        "/api/v1/purchase-payment-proposal/paid-value",
        data
      );
    },
    async deletePurchasePaymentProposal(id) {
      return this.$http.delete(`/api/v1/purchase-payment-proposal/${id}`);
    },
    async postPurchasePaymentRequest(id) {
      const data = { proposal_id: [id] };
      return this.$http.post("/api/v1/purchase-payment-request/generate", data);
    },
    getProposalInfo(id) {
      return this.$http.get("/api/v1/purchase-payment-proposal/line/" + id);
    },
    async get(id) {
      try {
        this.$vs.loading();
        const resp = await this.getProposalInfo(id);
        if (resp.code < 299) {
          this.currencyCode = resp.data.purchaseInvoice.CurrencyCode;
          this.SupplierCode = resp.data.purchaseInvoice.SupplierCode;
          this.SupplierID = resp.data.purchaseInvoice.SupplierID;
          console.log("supplier id", this.SupplierID);

          this.unpaidValues.push({
            id: id,
            purchase_invoice_id: resp.data.purchaseInvoice.ID,
            value: resp.data.unpaid_value,
            remaining_value: resp.data.unpaid_value,
          });

          console.log("unpaid values", this.unpaidValues);
        } else {
          throw new Error(resp.message);
        }
        this.$vs.loading.close();
        this.isLoading = false;
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
        this.isLoading = false;
      }
    },
    async handleClearing() {
      try {
        let selectedDN = JSON.parse(
          JSON.stringify(this.$store.state.clearingDN.selectedDebitNotes)
        );

        if (selectedDN.length === 0) {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Clearing",
            text: "Please select at least one debit note",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }

        selectedDN.sort((a, b) => a.value - b.value);
        console.log("selected DN", selectedDN);

        const selectedProposalPaidNow = JSON.parse(
          JSON.stringify(this.$store.state.clearingDN.proposalPaidNows)
        );

        if (
          !selectedProposalPaidNow.every(
            (item) => item.value <= item.remaining_value
          )
        ) {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Clearing",
            text: "Paid now cannot be more than purchase payment proposal value",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }

        selectedProposalPaidNow.sort(
          (a, b) => a.remaining_value - b.remaining_value
        );

        this.$vs.loading();

        console.log("selected proposal", selectedProposalPaidNow);

        let debit_note_ids = selectedDN.map((dn) => dn.id);
        const body = {
          debit_note_ids,
          posting_date: moment().format("YYYY-MM-DD"),
          description: "apply dn",
          invoices: this.selectedProposal.map((proposal) => {
            const proposalPaidNow = selectedProposalPaidNow.find(
              (item) => item.id === proposal.ID
            );

            console.log("proposal paid now", proposalPaidNow);

            return {
              paid_value: proposalPaidNow.value,
              purchase_payment_proposal_id: proposal.ID,
              purchase_invoice_code_internal:
                proposal.PurchaseInvoiceCodeInternal,
              purchase_invoice_code: proposal.PurchaseInvoiceCode,
              purchase_invoice_id: proposal.PurchaseInvoiceID,
            };
          }),
        };

        const resp = await this.$http.post(
          "/api/v1/debit-note/purchase-payment/purchase-invoice",
          body
        );

        if (resp.code <= 299) {
          this.$vs.loading.close();
          this.closeModal(true);
          this.$vs.notify({
            title: "Clearing",
            text: "clearing success",
            color: "success",
            position: "top-right",
            iconPack: "feather",
          });
        } else {
          this.$vs.loading.close();
          throw new Error(resp.message);
        }
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
        this.$vs.notify({
          title: "Clearing",
          text: e.message,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    async postClearing2() {
      try {
        this.$vs.loading();

        const selectedDN = JSON.parse(
          JSON.stringify(this.$store.state.clearingDN.selectedDebitNotes)
        );
        const selectedProposalPaidNow = JSON.parse(
          JSON.stringify(this.$store.state.clearingDN.proposalPaidNows)
        );

        console.log("selected proposal", selectedProposalPaidNow);

        let isNotFullyPaid = false;
        let dnIndex = 0;
        for (let i = 0; i < selectedProposalPaidNow.length; i++) {
          for (let y = dnIndex; y < selectedDN.length; y++) {
            if (selectedDN[y].value === 0) {
              continue;
            }
            let dnValue = selectedDN[y].value;
            let paid_now;
            //check weather the dn value is enough to pay the proposal
            if (dnValue - selectedProposalPaidNow[i].value < 0) {
              //if not enough, then the paid now value is the dn value
              paid_now = dnValue.toFixed(2);
              selectedDN[y].value = 0;
              selectedProposalPaidNow[i].value =
                selectedProposalPaidNow[i].value - dnValue;
            } else {
              //if enough, then the paid now value is the proposal value
              paid_now = selectedProposalPaidNow[i].value.toFixed(2);
              selectedDN[y].value -= selectedProposalPaidNow[i].value;
              selectedProposalPaidNow[i].value = 0;
            }
            const body = {
              debit_note_id: selectedDN[y].id,
              debit_note_code: selectedDN[y].code,
              posting_date: moment().format("YYYY-MM-DD"),
              description: "",
              notes: "-",
              type: 2,
              // invoice: this.selectedProposal.map((proposal) => ({
              //   ...proposal,
              //   paid_now,
              // })),
              invoice: [
                {
                  ...this.selectedProposal[i],
                  paid_now,
                },
              ],
            };
            // console.log(body);
            let resp = await this.$http.post(
              "/api/v1/debit-note/apply-to-invoice",
              body
            );
            let isSuccess = resp.code.toString().startsWith("2");
            if (!isSuccess) {
              this.$vs.loading.close();
              throw new Error(resp.message);
            }
            //check weather the proposal is fully paid
            if (selectedProposalPaidNow[i].value === 0) {
              //if fully paid, then delete the proposal
              resp = await this.deletePurchasePaymentProposal(
                selectedProposalPaidNow[i].id
              );
              isSuccess = resp.code.toString().startsWith("2");
              if (!isSuccess) {
                this.$vs.loading.close();
                throw new Error(resp.message);
              }
              break;
            } else if (
              //if not fully paid and it is the last DN, then update the 'isnotfullypaid' flag to true
              y === selectedDN.length - 1 &&
              selectedProposalPaidNow[i].value > 0
            ) {
              isNotFullyPaid = true;
              break;
            }
          }
          if (isNotFullyPaid) {
            const value = selectedProposalPaidNow[i].value.toFixed(2);
            const resp = await this.updatePriceValue(
              parseFloat(value),
              selectedProposalPaidNow[i].id
            );
            if (!resp.code.toString().startsWith("2")) {
              throw new Error(resp.message);
            }
            const resp2 = await this.postPurchasePaymentRequest(
              selectedProposalPaidNow[i].id
            );
            if (!resp2.code.toString().startsWith("2")) {
              throw new Error(resp.message);
            }
            isNotFullyPaid = false;
          }
          // if (!selectedDN.some((dn) => dn.value > 0)) {
          //   break;
          // }
          dnIndex = selectedDN.findIndex((dn) => dn.value > 0);
          if (dnIndex === -1) {
            break;
          }
        }

        this.$vs.loading.close();
        this.closeModal(true);
        this.$vs.notify({
          title: "Clearing",
          text: "clearing success",
          color: "success",
          position: "top-right",
          iconPack: "feather",
        });
      } catch (e) {
        console.log(e);
        this.$vs.notify({
          title: "Clearing",
          text: e.message,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    async postClearing() {
      try {
        this.$vs.loading();
        console.log("postClearing");
        //copy the state useing JSON method. this ensure that the state is deeply copied
        const selectedDN = this.$store.state.clearingDN.selectedDebitNotes;
        const selectedProposalPaidNow = JSON.parse(
          JSON.stringify(this.$store.state.clearingDN.proposalPaidNows)
        );
        let startProposalIndex = 0;
        let indexBuff = 0;
        for (const dn of selectedDN) {
          let dnValue = dn.dn_value - dn.used_dn_value;
          console.log("dn value", dnValue);
          let isdnValueZero = false;
          for (
            let index = startProposalIndex;
            index < selectedProposalPaidNow.length;
            index++
          ) {
            indexBuff = index;
            const proposalPaidNowValue = selectedProposalPaidNow[index].value;
            let paid_now;
            if (dnValue - proposalPaidNowValue < 0) {
              paid_now = dnValue.toFixed(2);
              selectedProposalPaidNow[index].value =
                proposalPaidNowValue - dnValue;
              isdnValueZero = true;
            } else {
              paid_now = proposalPaidNowValue.toFixed(2);
              selectedProposalPaidNow[index].value = 0;
            }
            const body = {
              debit_note_id: dn.dn_id,
              debit_note_code: dn.dn_code,
              posting_date: moment().format("YYYY-MM-DD"),
              description: "",
              notes: "-",
              type: 2,
              invoice: this.selectedProposal.map((proposal) => ({
                ...proposal,
                paid_now,
              })),
            };
            console.log(body);
            let resp = await this.$http.post(
              "/api/v1/debit-note/apply-to-invoice",
              body
            );
            let isSuccess = resp.code.toString().startsWith("2");
            if (!isSuccess) {
              this.$vs.loading.close();
              throw new Error(resp.message);
            }

            if (isdnValueZero) {
              startProposalIndex = indexBuff;
              break;
            }

            resp = await this.deletePurchasePaymentProposal(
              selectedProposalPaidNow[index].id
            );
            isSuccess = resp.code.toString().startsWith("2");
            if (!isSuccess) {
              this.$vs.loading.close();
              throw new Error(resp.message);
            }

            dnValue -= proposalPaidNowValue;
          }
          //check weather the invoice value is all zero
          if (!selectedProposalPaidNow.some((item) => item.value > 0)) {
            //value is all zero, then break the DN loop
            break;
          }
          console.log("clearing success");
        }
        const proposalWithRemaingPaidNow = selectedProposalPaidNow.find(
          (item) => item.value > 0
        );

        if (proposalWithRemaingPaidNow !== undefined) {
          console.log("still have remaining paid now");
          try {
            const resp = await this.updatePriceValue(
              proposalWithRemaingPaidNow.value,
              proposalWithRemaingPaidNow.id
            );
            if (!resp.code.toString().startsWith("2")) {
              throw new Error(resp.message);
            }
            const resp2 = await this.postPurchasePaymentRequest(
              proposalWithRemaingPaidNow.id
            );
            if (!resp2.code.toString().startsWith("2")) {
              throw new Error(resp.message);
            }
          } catch (e) {
            throw new Error(e.message);
          }
        }
        this.$vs.loading.close();
        this.closeModal(true);
        this.$vs.notify({
          title: "Clearing",
          text: "clearing success",
          color: "success",
          position: "top-right",
          iconPack: "feather",
        });
      } catch (e) {
        console.log(e);
        this.$vs.notify({
          title: "Clearing",
          text: e.message,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    resetIsLoading() {
      this.isLoading = true;
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        for (const proposal of this.selectedProposal) {
          this.get(proposal.ID);
        }
      } else {
        this.$store.commit("clearingDN/clearState");
        this.unpaidValues = [];
        this.supplierID = 0;
        this.supplierCode = "";
      }
    },
  },
};
</script>

<style>
.vs-popup--header {
  display: none !important;
}

.vs-popup--content {
  max-height: none !important;
  min-height: 100% !important;
}
</style>
