<template>
  <vs-input v-model="displayValue" type="" @blur="onblur" @keyup="onkeyup" />
</template>

<script>
export default {
  computed: {
    displayValue: {
      get() {
        return this.formatPrice(this.value.replace(/,/g, ""));
      },
      set(val) {
        this.value = val;
        const temp = [...this.$store.state.clearingDN.proposalPaidNows];
        const index = temp.findIndex((item) => item.id === this.proposalId);
        if (index > -1) {
          const tempVal = val.replace(/,/g, "");
          const newValue = parseFloat(tempVal) || 0;
          this.$store.commit("clearingDN/updateProposalPaidNow", {
            index,
            newValue,
          });
        } else {
          return;
        }
      },
    },
  },
  data() {
    return { value: "" };
  },
  mounted() {
    this.value = this.formatPrice(this.paidnowValue);
  },
  watch: {
    paidnowValue() {
      this.value = this.formatPrice(this.paidnowValue);
    },
  },
  props: {
    paidnowValue: Number,
    proposalId: Number,
  },
  methods: {
    formatPrice(angka) {
      return angka
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    onblur() {
      this.value = this.priceFormat(this.value.replace(/,/g, ""));
    },
    onkeyup() {
      this.value = this.formatPrice(this.value.replace(/,/g, ""));
    },
  },
};
</script>
