<template>
  <vs-card class="bg-primary text-white pt-2 px-4">
    <div class="flex flex-col h-full gap-2">
      <div slot="header">
        <h6 class="text-white">{{ title }}</h6>
      </div>
      <p class="text-2xl font-bold">{{ renderValue() }}</p>
    </div>
  </vs-card>
</template>

<script>
export default {
  props: {
    title: String,
    value: String,
  },
  methods: {
    renderValue() {
      return this.value === undefined || this.value === null ? "-" : this.value;
    },
  },
};
</script>
