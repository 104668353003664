<template>
  <div class="flex flex-col gap-6">
    <div class="flex flex-col gap-3 w-full">
      <span>Debit Note Number</span>
      <div class="flex gap-4">
        <multiselect
          v-model="selectedDebitNoteCodes"
          :options="debitNoteOption"
          placeholder="Type to search"
          track-by="id"
          :max-height="125"
          :allow-empty="true"
          :multiple="true"
          :limit="2"
          @search-change="onAddTag"
          @tag="onTagInput"
          label="code"
          ref="tagInput"
        >
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">
                <span class="option__title"> {{ props.option.code }}</span>
              </span>
            </div>
          </template>
        </multiselect>
        <vs-button
          color="danger"
          icon-pack="feather"
          icon="icon-x"
          @click="clearDebitNoteCodes"
        />
      </div>
    </div>

    <vs-table v-if="debitNotes.length > 0" :data="debitNotes">
      <template slot="thead">
        <vs-th></vs-th>
        <vs-th> DN Number </vs-th>
        <vs-th> Type </vs-th>
        <vs-th> Total Value </vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(debit, indextr) in data">
          <vs-td>
            <vs-checkbox
              :checked="checkboxValue(data[indextr].id)"
              @click="addCheckedDebitNotes(data[indextr])"
            />
          </vs-td>
          <vs-td>
            {{ data[indextr].code }}
          </vs-td>
          <vs-td>
            {{ data[indextr].type }}
          </vs-td>
          <vs-td>
            {{ priceFormatWithMinus(data[indextr].remaining_value) }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div v-else class="flex h-full justify-center items-center">
      <p class="text-lg">No data</p>
    </div>
  </div>

  <!-- <data-table
    :responseData="responseData"
    :propsParams="params"
    :header="header"
    @reloadDataFromChild="fetchDebitNotes"
  >
    <template slot="thead">
      <vs-th></vs-th>
      <vs-th> DN Number </vs-th>
      <vs-th> Type </vs-th>
      <vs-th> Total Value </vs-th>
    </template>
    <template slot="tbody">
      <vs-tr :key="indextr" v-for="(debit, indextr) in debitNotes">
        <vs-td>
          <vs-checkbox
            :checked="checkboxValue(debit.id)"
            @click="addCheckedDebitNotes(debit)"
            :disabled="isCheckboxEnabled(debit.id)"
          />
        </vs-td>
        <vs-td>
          {{ debit.code }}
        </vs-td>
        <vs-td>
          {{ debit.type }}
        </vs-td>
        <vs-td>
          {{ priceFormatWithMinus(debit.dn_value - debit.used_dn_value) }}
        </vs-td>
      </vs-tr>
    </template>
  </data-table> -->
</template>

<script>
export default {
  watch: {
    async isOpen(val) {
      if (!val) {
        this.debitNotes = [];
        this.checkedDebitNotes = [];
        this.$emit("resetIsLoading");
      }
    },

    selectedDebitNoteCodes() {
      this.$nextTick(() => {
        this.fetchDebitNotes(this.params);
      });
    },
  },
  props: {
    isOpen: Boolean,
    territoryID: Number,
    territoryAreaID: Number,
    supplierID: {
      type: Number,
      default: 0,
    },
    supplierCode: {
      type: String,
      default: "",
    },
    supplierName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "id",
        sort: "desc",
      },
      debitNotes: [],
      checkedDebitNoteIds: [],
      isInitialized: Boolean,
      responseData: {},
      debitNoteOption: [],
      selectedDebitNoteCodes: [],
    };
  },
  async mounted() {
    await this.fetchDebitNoteCodes("");
    await this.fetchDebitNotes(this.params);
  },
  methods: {
    async fetchDebitNotes(params) {
      // this.$vs.loading();
      // this.$http
      //   .get("api/v1/debit-note/dn", {
      //     params: {
      //       length: params.length,
      //       page: params.page,
      //       search: params.search,
      //       sort: params.sort,
      //       order: params.order,
      //       territory_id: 0,
      //       territory_area_id: 0,
      //       status: 1,
      //       type: "apply-dn",
      //     },
      //   })
      //   .then((resp) => {
      //     this.$vs.loading.close();
      //     this.responseData = resp.data;
      //     this.debitNotes = resp.data.records;
      //   });
      try {
        this.$vs.loading();
        const resp = await this.$http.get(
          "api/v1/debit-note/purchase-payment",
          {
            params: {
              length: params.length,
              page: params.page,
              search: params.search,
              sort: params.sort,
              order: params.order,
              supplier_id: this.supplierID,
              supplier_code: this.supplierCode,
              status: 1,
              codes: this.selectedDebitNoteCodes.map((data) => data.code),
            },
          }
        );
        if (resp.code === 200) {
          this.responseData = resp;
          this.debitNotes = resp.data;
        } else {
          this.responseData = {};
          this.debitNotes = [];
        }
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
      }
    },
    async fetchDebitNoteCodes(search) {
      try {
        this.$vs.loading();
        const resp = await this.$http.get("api/v1/debit-note/filter-code", {
          params: {
            length: 50,
            page: 1,
            search,
            supplier_id: this.supplierID,
            supplier_code: this.supplierCode,
            sort: "debit_notes.id",
            order: "desc",
            status: 1,
            settlement_method: "AP Offset",
          },
        });
        if (resp.code === 200) {
          this.debitNoteOption = resp.data;
        } else {
          this.debitNoteOption = [];
        }
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
      }
      // this.$vs.loading();
      // this.$http
      //   .get("api/v1/debit-note/filter-code", {
      //     params: {
      //       length: 50,
      //       page: 1,
      //       search,
      //       sort: "desc",
      //       order: "id",
      //       territory_id: 0,
      //       territory_area_id: 0,
      //       status: 1,
      //       type: "apply-dn",
      //     },
      //   })
      //   .then((resp) => {
      //     if (resp.code === 200) {
      //       this.debitNoteOption = resp.data;
      //     } else {
      //       this.debitNoteOption = [];
      //     }
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });
    },
    addCheckedDebitNotes(debitNote) {
      const temp = [...this.$store.state.clearingDN.selectedDebitNotes];
      if (temp.some((val) => debitNote.id === val.id)) {
        const index = temp.findIndex((val) => val.id === debitNote.id);
        temp.splice(index, 1);
      } else {
        const dnObj = {
          id: debitNote.id,
          value: debitNote.remaining_value,
          code: debitNote.code,
        };
        temp.push(dnObj);
      }
      this.$store.commit("clearingDN/setSelectedDebitNotes", temp);
    },

    checkboxValue(id) {
      return this.$store.state.clearingDN.selectedDebitNotes.some(
        (val) => val.id === id
      );
    },

    isCheckboxEnabled(id) {
      if (
        this.$store.state.clearingDN.totalDebitNotesMinusPaidNows > 0 ||
        !this.isInitialized
      ) {
        return "false";
      }

      if (
        this.$store.state.clearingDN.selectedDebitNotes.some(
          (val) => val.id === id
        )
      ) {
        return "false";
      } else {
        return "true";
      }
    },
    priceFormatWithMinus(amount) {
      const newAmount = amount
        .toString()
        .replace(/[^.\d-]/g, "")
        .toString();
      // console.log(amount, newAmount)
      amount = parseFloat(newAmount).toFixed(2);

      return `${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    },
    async onAddTag(tag) {
      console.log(tag);
      const tags = tag.replace(/\s+/g, "").trim().split(";");

      if (tag.includes(";")) {
        console.log(tags.length);
        for (let i = 0; i < tags.length; i++) {
          const tagObject = {
            id: this.selectedDebitNoteCodes.length + 1,
            code: tags[i],
          };
          this.selectedDebitNoteCodes.push(tagObject);
        }
        // this.invoiceCodeTags.pop();
        this.$refs.tagInput.deactivate();
      } else {
        if (tag.length < 3 && tag.length > 0) {
          return;
        }

        this.$vs.loading();
        const resp = await this.fetchDebitNoteCodes(tag);
        if (resp.code === 200) {
          this.debitNoteOption = resp.data;
        } else {
          this.debitNoteOption = [];
        }
        this.$vs.loading.close();
      }
    },
    onTagInput(tag) {
      const tagObject = {
        id: this.selectedDebitNoteCodes.length + 1,
        code: tag,
      };
      this.selectedDebitNoteCodes.push(tagObject);
    },
    clearDebitNoteCodes() {
      this.selectedDebitNoteCodes = [];
    },
    queryDnNumber({ code }) {
      if (!code) {
        return "invalid";
      }
      return code;
    },
  },
};
</script>
