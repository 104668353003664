<template>
    <v-select 
        multiple 
        :filterable="true" 
        :options="options" 
        v-model="selected" 
        :placeholder="placeholder"
        @input="val => { onChange(val); }" />
</template>

<script>
import vSelect from "vue-select";
export default {
    components: {
        vSelect,
    },
    props: {
        draw: {
            type: Number,
        },
        placeholder: {
            type: String
        },
        value: {
            type: Object
        },
        territoryId: {
            type: Array,
        },
    },
    data() {
        return {
            options: [],
            selected: []
        };
    },
    methods: {
        onChange(val) {
            if (!val.length) {
                this.selected[0] = this.options[0]
            } else if (val.length > 1) {
                this.selected = this.selected.filter(item => item.id !== 0);
            }
        }
    },
    watch: {
        selected(v) {
            this.selected = v;
            this.$emit("selected", v);
        },
        territoryId(v) {
            this.options = []
            this.selected = []

            let territoryIds = [];
            this.territoryId.forEach(val => {
                territoryIds.push(val.id)
            });

            let param = {
                territory_id: territoryIds,
            }

            this.$http
                .post("/api/v1/canvas/filter-salesmans", param)
                .then((resp) => {
                    if (resp.code == 200) {
                        if (resp.data.sales.length > 0) {
                            this.options.push({ id: 0, label: 'All' });
                            this.options.push(...resp.data.sales);
                            if (resp.data.default_sales.id > 0) {
                                if (territoryIds[0] == 0) {
                                    this.selected.push({ id: 0, label: 'All' });
                                } else {
                                    this.selected.push(resp.data.default_sales)
                                }
                            } else {
                                this.selected.push({ id: 0, label: 'All' });
                            }
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        value(v) {
            if (v) {
                this.selected = v
            }
        }
    },
    mounted() { }
}
</script>