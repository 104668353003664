<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Detail Payment</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Supplier</label>
        <multiselect
          class="selectExample"
          v-model="supplier"
          :options="optionSupplier"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="false"
        >
          <!-- <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </div>
          </template> -->
        </multiselect>
      </div>
      <div class="vx-col w-full">
        <label class="vs-input--label">Payment Proposal</label>
        <multiselect
          class="selectExample"
          v-model="paymentProposal"
          :options="optionPaymentProposal"
          :multiple="true"
          :allow-empty="true"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Code"
          @search-change="getOptionPaymentProposal"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Code }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Code }}</span>
              <br>
              <span class="option__desc"> Due Date : {{ dateFormat(dt.option.DueDate)}}</span>
              <br>
              <span class="option__desc"> Paid Proposal ({{ priceFormat(dt.option.PaidValue)}})</span>
            </div>
          </template>
        </multiselect>
      </div>

      <div class="vx-col w-full">
        <template v-for="proposal in paymentProposal">
          <div v-bind:key="proposal.ID">
            <vs-divider style="width: 100%;"><b>{{proposal.Code}}</b></vs-divider>
            Code : {{proposal.PurchaseInvoiceCode}}<br>
            Due Date : {{dateFormat(proposal.DueDate)}}<br>
            Paid Proposal : {{priceFormat(proposal.PaidValue)}}<br>
            <vs-divider style="width: 100%;"></vs-divider>

          </div>
        </template>
      </div>
      <div class="vx-col w-full">
        <label class="vs-input--label">Operating Unit Bank</label>
        <multiselect
          class="selectExample"
          v-model="operatingUnitBank"
          :options="optionBankOperatingUnit"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{
               bank.filter(v => {
                  return v.ID == dt.option.BankID
                })[0].Name + ' ' + dt.option.AccountNumber + ' (' + dt.option.AccountName + ')'
              }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{
                bank.filter(v => {
                  return v.ID == dt.option.BankID
                })[0].Name + ' ' + dt.option.AccountNumber + ' (' + dt.option.AccountName + ')'
              }}</span>
            </div>
          </template>
        </multiselect>
      </div>
      <div class="vx-col w-full">
        <label class="vs-input--label">Supplier Bank</label>
        <multiselect
          class="selectExample"
          v-model="supplierBank"
          :options="optionBankSupplier"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="BankName"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{dt.option.BankName}} {{dt.option.AccountNumber}} ({{dt.option.AccountName}})
               </span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">
                {{dt.option.BankName}} {{dt.option.AccountNumber}} ({{dt.option.AccountName}})
               </span>
            </div>
          </template>
        </multiselect>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%"><b>Notes</b></vs-divider>
      <template v-for="note in notes">
        <b v-bind:key="note">{{note}}
          <br>
        </b>
      </template>
      <div class="vx-col w-full">
        <br>
        <vs-button class="mb-2" v-on:click="handleReversal">Reversal Payment</vs-button>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment"

export default {
  components: {
    Datepicker,
  },
  created() {},
  data() {
    return this.initialState();
  },
  props: {
      selected: Object,
  },
  methods: {
    initialState() {
      return {
        search: "",
        supplier: null,
        operatingUnitBank: null,
        supplierBank: null,
        bank: [],
        paymentProposal: [],
        optionSupplier: [],
        optionPaymentProposal: [],
        optionBankOperatingUnit: [],
        optionBankSupplier: [],
        notes:[],
      };
    },
    handleReversal() {
      // //console.log(this.operatingUnitBank,this.supplierBank)
      
      const params = {
        id:this.selected.ID,
        purchase_payment_request_id: this.selected.ID,
        status_purchase_request: 5,
        reference_code:this.reference_code,
      }
      this.$vs.loading();
      //console.log(params)
      this.$http.post('/api/v1/purchase-payment-request/change-status',params)
        .then(result => {
          //console.log(result)
          this.$vs.loading.close();
          if (result.code == 200) {
            this.handleClose()
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to update",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        }).catch(e => {

        })
    
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    getOptionPaymentProposal(query) {
      //console.log(this.supplier)
      this.$http.get('/api/v1/purchase-payment-proposal',{
            params: {
                status: 1,
                request_code: this.selected.Code,
                search: query,
                length: 100,
                still_empty: 1,
                // page: params.page,
                // order: params.sort,
                // sort: params.order,
                supplier_code: this.supplier.Code
            }
        }).then((r) => {
          this.optionPaymentProposal = r.data.purchasePaymentProposal
          //console.log(r)
        }).catch((e) => {
          //console.log(e)
        })
    },
    getOptionBankOperationUnit() {
      this.$http.get('/api/v1/purchase-payment-proposal/bank-operating-unit').then((r) => {
          this.optionBankOperatingUnit = r.data.dataOperatingUnitBank
          this.bank = r.data.dataBank
          // //console.log(r)
          if (this.selected.ID) {
            r.data.dataOperatingUnitBank.map(v => {
              if (v.BankID == this.selected.BankID && v.AccountNumber == this.selected.AccountNumber) {
                  this.operatingUnitBank = v
              }
            }) 
          }
        }).catch((e) => {
          //console.log(e)
        })
    },
    getOptionSupplierBank() {
      this.$http.get('/api/v1/pi/supplier-account/' + this.supplier.ID).then((r) => {
        this.optionBankSupplier = r.data.account
        //console.log(this.selected)
        if (this.selected.ID) {
          r.data.account.map(v => {
            if (v.BankName == this.selected.ToBankName && v.AccountNumber == this.selected.ToAccountNumber) {
                  this.supplierBank = v
            }
          }) 
        }
        //console.log(r)
      }).catch((e) => {
        //console.log(e)
      })

    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/purchase-payment-request/line/" + this.selected.ID)
        .then(result => {
          // this.$vs.loading.close();
          this.paymentProposal = []
          result.data.purchasePaymentProposal.map( v => {
            this.paymentProposal.push(v)
          })
          this.notes = this.selected.Notes.toString().split(";")
          //console.log(result)
        })
        .catch(e => {
          this.$vs.loading.close();

        })
    },
    getOptionSupplier(query) {
      if (query.length <= 2) {
        return
      }
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/suppliers", {
          params: {
            order: "name",
            sort: "asc",
            search: query,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionSupplier = resp.data.records;
            if (this.selected.ID) {
              resp.data.records.map(v => {
                if (v.Code == this.selected.SupplierCode) {
                  this.supplier = v
                }
              })
            }
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Supplier option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
  },
  mounted() {
    this.getOptionBankOperationUnit();
    this.getData()
    this.getOptionSupplier(this.selected.SupplierName)
    // this.getOptionPaymentTerm();
    // this.getOptionSupplier();
    // this.getOptionWarehouse();
  },
  computed: {},
  watch: {
    "supplier.ID"(){ 
      this.getOptionSupplierBank()
    },
    "selected.ID"() {
      //console.log("selected change")
      this.getData()
      this.getOptionSupplier(this.selected.SupplierName)
    }
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>