<template>
  <div class="vx-row mb-12">
    <div
      v-bind:class="[
        detail ? detailShow + ' vertical-divider' : '',
        detailHide,
      ]"
    >
      <div class="flex flex-row gap-4 w-full mb-6 mt-2">
        <vs-button v-on:click="printPDF(false)">Print</vs-button>
        <vs-button v-on:click="openModal()">Clearing With DN</vs-button>
        <vs-button @click="createPayment">Group</vs-button>
        <!-- <vs-button @click="showPaymentCodePopup = !showPaymentCodePopup"
          >dummy</vs-button
        > -->
      </div>

      <clearing-modal
        :isOpen="isModalOpen"
        :selectedProposal="checkedCode"
        @closeModal="closeModal($event)"
        @closeModalWithReload="closeModalWithReload($event)"
      />

      <data-table
        :responseData="responseData"
        :propsParams="params"
        :header="header"
        @reloadDataFromChild="reloadData"
      >
        <template slot="thead">
          <th width="7%">Check</th>
          <th width="7%">Action</th>
        </template>
        <template slot="tbody">
          <vs-tr
            :key="indextr"
            v-for="(tr, indextr) in responseData.purchasePaymentProposal"
            :class="tr.class"
          >
            <vs-td>
              <vs-checkbox
                :checked="checked.includes(data[indextr].ID)"
                v-on:click="addChecked(data[indextr])"
              />
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <template>
                <div class="mt-4 flex justify-between">
                  <vx-tooltip text="Cancel Payment Proposal" class="mr-4">
                    <vs-button
                      type="line"
                      color="red"
                      icon-pack="feather"
                      icon="icon-x"
                      v-on:click.stop="doCancel(tr)"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Show Invoice" class="mr-4">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-eye"
                      v-on:click.stop="handleShow(tr)"
                    />
                  </vx-tooltip>
                </div>
              </template>
            </vs-td>

            <vs-td>
              {{ tr.Code }}
            </vs-td>
            <vs-td>
              Code : {{ tr.SupplierCode }}<br />
              Name : {{ tr.SupplierName }}
            </vs-td>
            <vs-td>
              {{ tr.DeliveryNumber }}
            </vs-td>
            <vs-td>
              External Number : {{ tr.PurchaseInvoiceCode }}<br />
              Internal Number : {{ tr.PurchaseInvoiceCodeInternal }}<br />
              Total Value : {{ priceFormat(tr.PurchaseInvoiceValue) }}<br />
              Due Date : {{ dateFormat(tr.DueDate) }}<br />
              Plan Payment Date : {{ dateFormat(tr.PurposePaymentDate) }} <br />
              Type :
              {{ tr.po_type }}
            </vs-td>
            <vs-td> {{ priceFormat(tr.PaidValue) }}<br /> </vs-td>
          </vs-tr>
        </template>
      </data-table>
      <!-- Approve Prompt -->
      <vs-prompt
        title="Confirmation"
        color="primary"
        @accept="doYes"
        @cancel="doNo"
        @close="closeDetail"
        :accept-text="'Yes'"
        :cancel-text="'No'"
        :buttons-hidden="false"
        :active.sync="confirmation"
      >
        <div class="con-exemple-prompt">
          Create Payment
          <br />

          Process due date only?
          <br />
          <br />
          <!-- <vs-textarea v-model="notes"/> -->
        </div>
      </vs-prompt>
      <vs-popup
        fullscreen
        title="fullscreen"
        :active.sync="showPdf"
        :button-close-hidden="false"
      >
        <div class="flex flex-col gap-6 h-full">
          <iframe :src="pdfUrlPopup" class="w-full" style="min-height: 80vh" />
          <div class="flex gap-3 w-full justify-end">
            <vs-button color="danger" @click="handleClosePopUp"
              >Cancel</vs-button
            >
            <vs-Button @click="handlePrintPDFPreview">Print</vs-Button>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        title="Created Grup Code"
        :active.sync="showPaymentCodePopup"
        :button-close-hidden="false"
      >
        <div class="flex flex-col gap-6 h-full justify-center items-center">
          <h4 class="text-primary">Group creation success</h4>
          <div style="width: 100%; max-height: 250px; overflow: auto">
            <vs-table :data="createdPaymentCodes">
              <template slot="thead">
                <vs-th> Codes </vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="tr">
                    {{ tr }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
          <div class="w-full justify-end flex">
            <vs-button color="danger" @click="showPaymentCodePopup = false"
              >Close</vs-button
            >
          </div>
        </div>
      </vs-popup>

      <vs-prompt
        title="Print Confirmation"
        color="primary"
        @accept="printPDF(true)"
        @cancel="closeReprintPrompt"
        @close="closeReprintPrompt"
        :accept-text="'Yes'"
        :cancel-text="'No'"
        :buttons-hidden="false"
        :active.sync="reprintPrompt"
      >
        <div class="font-bold" style="width: fit-content">
          <span>{{ reprintMessage }}</span>
          <br />
          <span>Are you sure ?</span>
        </div>
      </vs-prompt>
    </div>
    <!-- form -->
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[
          detail ? detailShow + 'md:w-2/3 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>
          <vs-col
            vs-offset="0"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="1"
          >
            <vs-button
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
            ></vs-button>
          </vs-col>
          <detail-div @close="handleClose" :selected="selectedData" />
        </vs-row>
      </div>
    </transition>
  </div>
</template>
<script>
import detail from "./detail.vue";
import moment from "moment";
import clearingModal from "./clearing-modal.vue";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import angkaTerbilang from "@develoka/angka-terbilang-js";
import TabRejectedVue from "../../../canvas/item-request/TabRejected.vue";
// import { dataPaymentCollection } from "../../../../../services/api/invoice";
export default {
  components: {
    "detail-div": detail,
    "clearing-modal": clearingModal,
  },
  props: {
    selected: Object,
    option: Object,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      checked: [],
      checkedCode: [],
      confirmation: false,
      isModalOpen: false,
      header: [
        {
          text: "Code",
          value: "code",
          // width: '5%'
        },
        {
          text: "Supplier",
          value: "supplier_code",
        },
        {
          text: "No Shipment",
          value: "delivery_number",
        },
        {
          text: "Invoice",
          value: "purchase_invoice_code",
        },
        {
          text: "Value",
          sortable: false,
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      notes: "",
      approvalPrompt: false,
      rejectPrompt: false,
      selectedData: {},
      pdfUrlPopup: "",
      showPdf: false,
      pdfProxyClass: null,
      contentHeight: "",
      rowHeight: "",
      createdPaymentCodes: [
        // "a3f7Yz9Wq4T2",
        // "b9Wq4Tc6D1Lp",
        // "c6D1Lp8J2Fk5",
        // "d8J2Fk5M0Hv7",
        // "e5M0Hv7Q3Gs6",
        // "f7Q3Gs4N6Bw8",
        // "g4N6Bw2Z8Cx1",
        // "h2Z8Cx1X5Rm0",
        // "i1X5Rm0V9Tp3",
        // "j0V9Tp3a3f7Y",
      ],
      showPaymentCodePopup: false,
      multipleReprint: false,
      reprintPrompt: false,
      proposals: [],
      usingCloseButton: false,
    };
  },
  computed: {
    reprintMessage() {
      return !this.multipleReprint
        ? "You are going to reprint this proposal."
        : "Some of the selected proposal has been printed and will be reprinted.";
    },
  },
  watch: {
    checkedCode() {
      console.log("Checked", this.checkedCode);
    },
    showPdf() {
      if (this.showPdf) {
        return;
      }

      if (!this.usingCloseButton) {
        this.showPdf = true;
      } else {
        this.usingCloseButton = false;
      }
    },
  },
  mounted() {
    // this.reloadData(this.params);
    this.contentHeight = `calc(${window.innerHeight}px - 80px)`;
    this.rowHeight = `calc(${window.innerHeight}px - 280px)`;
  },
  methods: {
    doCancel(selected) {
      // console.log(selected)
      this.$vs.loading();
      const params = {
        status: 3,
        propose_id: selected.ID,
        purchase_invoice_id: selected.PurchaseInvoiceID,
        notes: "",
      };

      this.$http
        .post("/api/v1/purchase-payment-proposal/change-status", params)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();

            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });

            this.createdPaymentCodes = resp.data;
            this.showPaymentCodePopup = true;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "error catch",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log(e);
        });
    },
    doYes() {
      this.checkedCode.map((v) => {
        if (
          parseInt(moment(v.DueDate).format("YYYYMMDD")) >
          parseInt(moment().format("YYYYMMDD"))
        ) {
          this.checked = this.checked.filter((subv) => {
            return v.ID != subv;
          });
        }
      });
      if (this.checked.length > 0) {
        this.doGenerate();
      } else {
        this.$vs.notify({
          color: "danger",
          title: "not found due date only on selected data",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
      // console.log(this.checked)
    },
    doNo() {
      this.doGenerate();
      // console.log('no')
    },
    doGenerate() {
      let params = {
        proposal_id: this.checked,
      };
      this.$vs.loading();
      this.$http
        .post("/api/v1/purchase-payment-request/generate", params)
        .then((r) => {
          // console.log(r)
          this.$vs.loading.close();
          if (r.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.createdPaymentCodes = r.data;
            this.showPaymentCodePopup = true;
          } else {
            this.$vs.notify({
              color: "danger",
              title: r.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$nextTick(() => {
            this.checked = [];
            this.checkedCode = [];
            this.reloadData(this.params);
          });
        })
        .catch((e) => {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          this.$vs.loading.close();
        });
    },
    addChecked(val) {
      if (this.checked.includes(val.ID)) {
        this.checked.splice(this.checked.indexOf(val.ID), 1);
        this.checkedCode = this.checkedCode.filter((v) => {
          return v.ID != val.ID;
        });
        // this.checkedCode.splice(this.checkedCode.indexOf(val.Code), 1);
      } else {
        this.checked.push(val.ID);
        this.checkedCode.push(val);
      }
    },
    createPayment() {
      console.log("here gess");
      if (this.checked.length === 0 || this.checkedCode.length === 0) {
        this.$vs.notify({
          color: "danger",
          title: "Create Payment",
          text: "Please select at least one proposal to be processed",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      if (!this.validateSupplier()) {
        return;
      }

      console.log("here gess");
      this.confirmation = true;
    },
    openModal() {
      if (this.checked.length === 0 || this.checkedCode.length === 0) {
        this.$vs.notify({
          color: "danger",
          title: "Clearing With DN",
          text: "Please select at least one shipment to be processed",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      if (!this.validateSupplier()) {
        return;
      }

      if (
        this.checkedCode.some((val) => val.Type !== this.checkedCode[0].Type)
      ) {
        this.$vs.notify({
          color: "danger",
          title: "Clearing With DN",
          text: "Proposal type are not the same, please select the same type of proposal to be processed",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }
      this.isModalOpen = true;
    },
    closeModal(value) {
      this.isModalOpen = value;
      this.checked = [];
      this.checkedCode = [];
    },
    closeModalWithReload(value) {
      this.isModalOpen = value;
      this.checked = [];
      this.checkedCode = [];
      this.reloadData(this.params);
    },
    handleApprove(selected) {
      selected.class = "highlight";
      this.selectedData = selected;
      this.rejectPrompt = false;
      this.approvalPrompt = true;
    },
    dateFormat(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    handleReject(selected) {
      selected.class = "highlight";
      this.selectedData = selected;
      this.approvalPrompt = false;
      this.rejectPrompt = true;
    },
    approve() {
      console.log(this.selectedData);
    },
    closeDetail() {
      this.activePrompt2 = false;
      this.detail = false;
      this.selectedData.class = "";
      this.selectedData = {
        ID: 0,
      };
    },
    closeReprintPrompt() {
      this.reprintPrompt = false;
      this.proposals = [];
      this.multipleReprint = false;
    },
    dataTable(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/purchase-payment-proposal", {
            params: {
              status: 1,
              still_empty: 1,
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.sort,
              sort: params.order,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    handleShow(selected) {
      this.selectedData.class = "";
      selected.class = "highlight";
      this.selectedData = selected;
      this.detail = true;
    },
    handleClose() {
      this.selectedData.class = "";
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      this.reloadData(this.params);
    },
    reloadData(params) {
      this.params = params;
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.purchasePaymentProposal;
          this.responseData = r.data;
          this.responseData.length = r.data.purchasePaymentProposal.length;
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    updatePrintCount(purchase_payment_ids) {
      return this.$http.put(`/api/v1/purchase-payment-request/print-count`, {
        purchase_payment_ids,
      });
    },
    setPos(pageWidth, pos, offset) {
      if (pos >= pageWidth) {
        return pageWidth;
      } else {
        if (pos <= 0) {
          return offset;
        } else {
          return (pos += offset);
        }
      }
    },
    getProposalInfo(id) {
      return this.$http.get("/api/v1/purchase-payment-proposal/line/" + id);
    },

    generateSingleDocumentInfo(doc, data, startY, pageWidth) {
      console.log("data", data);
      const header = [
        { header: "", dataKey: "title" },
        { header: "", dataKey: "value" },
      ];
      // const invoice = data.linesInfo.purchaseInvoice.purchaseInvoice;
      const body = [
        { title: "No Dokumen", value: data.PurchaseInvoiceCodeInternal },
        {
          title: "Tgl Dokumen",
          value: moment(data.PurchaseInvoiceCreatedAt).format("DD.MM.YYYY"),
        },
        {
          title: "No Invoice Vendor",
          value: data.linesInfo.purchaseInvoice.TaxReturnNumber
            ? data.linesInfo.purchaseInvoice.TaxReturnNumber
            : "-",
        },
        { title: "Profit Center", value: "Profit Center" },
        { title: "BA", value: this.getBA(data.TerritoryID) },
        {
          title: "Tgl Jatuh Tempo",
          value: moment(data.DueDate).format("DD.MM.YYYY"),
        },
        {
          title: "Jumlah Tercetak",
          value: data.linesInfo.purchasePaymentProposal.PrintCount + 1,
        },
      ];
      autoTable(doc, {
        startY: startY + 0.2,
        columns: header,
        body: body,
        showHead: "never",
        styles: {
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
          fontSize: 12,
          minCellHeight: 0.7,
          cellPadding: 0.2,
          font: "times",
        },
        columnStyles: {
          title: { cellWidth: 5 },
          value: { cellWidth: 5 },
        },
        theme: "plain",
        margin: { left: this.setPos(pageWidth, pageWidth - 10, 1), right: 1 },
        didParseCell(data) {
          data.cell.styles.fontSize = 11;
          const columnIndex = data.column.index;
          if (columnIndex === 0) {
            data.cell.styles.fontStyle = "bold";
          }
        },
      });

      return doc.lastAutoTable.finalY;
    },
    generateSingleDocumentInvoice(doc, data, startY, pageWidth) {
      const header = [
        { header: "Keterangan", dataKey: "title" },
        { header: "Nilai", dataKey: "value" },
      ];
      const invoice = data.linesInfo.purchaseInvoice;
      const invoiceLine = [...data.linesInfo.linePurchaseInvoice];
      const pph = invoiceLine.reduce((acc, val) => {
        if (invoice.IsSkb !== 1) {
          return acc + val.WhTax;
        } else {
          return acc;
        }
      }, 0);
      console.log("ini pph ges", pph);
      const ppn = invoice.VatExpired > 0 ? 0 : invoice.Tax;
      let beforeTax = invoice.TotalInvoiceValue - ppn;
      let requestPayment = beforeTax + ppn - pph;
      const pad = 32;
      const tax = [
        `${
          invoice.CurrencyCode != undefined ? invoice.CurrencyCode : ""
        }${this.priceFormat(beforeTax).padStart(pad, " ")}`,
        `${this.priceFormat(ppn).padStart(pad, " ")}`,
        `${this.priceFormat(pph).padStart(pad, " ")}`,
      ];
      const subTotal = `${
        invoice.CurrencyCode != undefined ? invoice.CurrencyCode : ""
      }${this.priceFormat(requestPayment).padStart(pad, " ")}`;

      let debitNoteMemo = 0;
      let partialPayment = 0;
      let creditNoteMemo = 0;

      data.linesInfo.purchasePayments.forEach((payment) => {
        if (payment.DebitNoteID != 0) {
          debitNoteMemo += payment.PaidInvoiceValue;
        } else {
          partialPayment += payment.PaidInvoiceValue;
        }
      });

      const payment = [
        this.priceFormat(partialPayment).padStart(pad, " "),
        this.priceFormat(creditNoteMemo).padStart(pad, " "),
        this.priceFormat(debitNoteMemo).padStart(pad, " "),
      ];

      const other = [
        this.priceFormat(0).padStart(pad, " "),
        this.priceFormat(0).padStart(pad, " "),
      ];

      requestPayment =
        requestPayment - partialPayment - creditNoteMemo - debitNoteMemo;

      const total = `${
        invoice.CurrencyCode != undefined ? invoice.CurrencyCode : ""
      }${this.priceFormat(requestPayment).padStart(pad, " ")}`;

      const body = [
        {
          title: "Nilai sebelum Pajak\nPPN\n-/- PPh",
          value: tax.join("\n"),
        },
        {
          title: "Sub Total",
          value: subTotal,
        },
        {
          title: "Partial Payment\nCredit Memo\nDebit Memo",
          value: payment.join("\n"),
        },
        {
          title: "Total",
          value: total,
        },
        {
          title: "Outstanding Balance\nRounding",
          value: other.join("\n"),
        },
        {
          title: "Payment Request",
          value: this.priceFormat(requestPayment).padStart(pad, " "),
        },
      ];

      autoTable(doc, {
        startY: startY + 0.2,
        columns: header,
        body: body,
        styles: {
          lineWidth: 0.0,
          lineColor: [0, 0, 0],
          fontSize: 12,
          minCellHeight: 0.7,
          cellPadding: 0.2,
          valign: "middle",
          halign: "right",
          font: "times",
        },
        headStyles: {
          fillColor: [219, 219, 219],
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
          fontStyle: "bold",
          valign: "middle",
          halign: "center",
          cellPadding: 0.1,
          minCellHeight: 0.1,
          font: "times",
        },
        columnStyles: {
          title: { cellWidth: this.setPos(pageWidth, 12, 1) },
          value: { cellWidth: this.setPos(pageWidth, pageWidth - 14, 1) },
        },
        theme: "plain",
        margin: { left: 1, right: 1 },
        didParseCell(data) {
          data.cell.styles.fontSize = 11;
          const rowIndex = data.row.index;
          const columnIndex = data.column.index;
          const cellData = data.cell.raw;
          if (
            rowIndex % 2 !== 0 ||
            cellData.includes(header[columnIndex].header)
          ) {
            data.cell.styles.fontStyle = "bold";
          } else {
            data.cell.styles.fontStyle = "normal";
          }
        },
        didDrawCell(data) {
          const rowIndex = data.row.index;
          const columnIndex = data.column.index;
          const cellX = data.cell.x;
          const cellY = data.cell.y;
          if (rowIndex > 0 && columnIndex === 1) {
            doc.setLineWidth(0.01);
            doc.setDrawColor(0, 0, 0);
            doc.line(cellX + 2, cellY, cellX + data.cell.width, cellY);
          }
        },
      });

      return {
        startY: doc.lastAutoTable.finalY,
        requestPayment,
      };
    },
    generateFooter(doc, startY, pageWidth, pageHeight) {
      const header = [
        { header: "Dibuat oleh:", dataKey: "a" },
        { header: "Diketahui", dataKey: "b" },
        { header: "Disetujui I:", dataKey: "c" },
        { header: "Disetujui II", dataKey: "d" },
      ];
      const width = this.setPos(pageWidth, pageWidth, 1) / 4;
      autoTable(doc, {
        startY: startY,
        columns: header,
        body: [{ a: "", b: "", c: "", d: "" }],
        styles: {
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
          fontSize: 12,
          minCellHeight: 2,
          cellPadding: 0.2,
          font: "times",
        },
        headStyles: {
          fillColor: [219, 219, 219],
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
          fontStyle: "bold",
          valign: "middle",
          halign: "center",
          cellPadding: 0.1,
          minCellHeight: 0.1,
          font: "times",
        },
        columnStyles: {
          1: { cellWidth: width },
          2: { cellWidth: width },
          3: { cellWidth: width },
          4: { cellWidth: width },
        },
        theme: "plain",
        margin: { left: 1, right: 1 },
        didParseCell(data) {
          data.cell.styles.fontSize = 11;
        },
      });

      let cursorY = doc.lastAutoTable.finalY;
      let diff = doc.lastAutoTable.finalY;
      cursorY -= 0.5;

      doc.setFont("times", "bold");
      doc.text(
        "Sudah dibayar dengan :",
        this.setPos(pageWidth, 0, 1),
        this.setPos(pageHeight, cursorY, 1)
      );
      cursorY += 0.5;

      doc.setFont("times", "normal");
      doc.text(
        "Cheque/BG No.",
        this.setPos(pageWidth, 0, 1),
        this.setPos(pageHeight, cursorY, 1)
      );

      doc.text(
        ":.........................",
        this.setPos(pageWidth, this.setPos(pageWidth, 4, 1), 1),
        this.setPos(pageHeight, cursorY, 1)
      );

      cursorY += 1;
      doc.setFont("times", "bold");
      doc.text(
        "Diterima oleh :",
        this.setPos(pageWidth, 0, 1),
        this.setPos(pageHeight, cursorY, 1)
      );

      cursorY += 0.5;
      doc.setFont("times", "normal");
      doc.text(
        "Nama",
        this.setPos(pageWidth, 0, 1),
        this.setPos(pageHeight, cursorY, 1)
      );
      doc.text(
        ":.........................",
        this.setPos(pageWidth, this.setPos(pageWidth, 4, 1), 1),
        this.setPos(pageHeight, cursorY, 1)
      );

      cursorY += 0.5;
      doc.text(
        "Tanggal",
        this.setPos(pageWidth, 0, 1),
        this.setPos(pageHeight, cursorY, 1)
      );
      doc.text(
        ":.........................",
        this.setPos(pageWidth, this.setPos(pageWidth, 4, 1), 1),
        this.setPos(pageHeight, cursorY, 1)
      );

      cursorY += 1;
      doc.text(
        "Tanda tangan",
        this.setPos(pageWidth, 0, 1),
        this.setPos(pageHeight, cursorY, 1)
      );
      doc.text(
        ":.........................",
        this.setPos(pageWidth, this.setPos(pageWidth, 4, 1), 1),
        this.setPos(pageHeight, cursorY, 1)
      );

      console.log("heigh", cursorY - diff);
    },
    generateMultipleDocumentInvoice(doc, proposals, startY, pageWidth) {
      const header = [
        { header: "No. Doc", dataKey: "docNumber" },
        { header: "Tgl Doc", dataKey: "docDate" },
        { header: "PCtr", dataKey: "profitCenter" },
        { header: "BA", dataKey: "ba" },
        { header: "Tgl Jatuh Tempo", dataKey: "dueDate" },
        { header: "Nilai Sblm Pajak", dataKey: "beforeTaxValue" },
        { header: "PPN", dataKey: "ppn" },
        { header: "PPh", dataKey: "pph" },
        { header: "Payment Request", dataKey: "paymentRequest" },
      ];

      const body = [];
      let proposalIndex = 0;
      let totalInvoiceValue = 0;
      let isGreyedOut = false;
      let rowIndexBefore = 0;
      for (const proposal of proposals) {
        const invoice = proposal.linesInfo.purchaseInvoice;
        const invoiceLine = [...proposal.linesInfo.linePurchaseInvoice];
        const pph = invoiceLine.reduce((acc, val) => {
          if (invoice.IsSkb !== 1) {
            return acc + val.WhTax;
          } else {
            return acc;
          }
        }, 0);
        const ppn = invoice.VatExpired > 0 ? 0 : invoice.Tax;
        const beforeTax = invoice.TotalInvoiceValue - ppn;
        // const paymentRequest = invoice.TotalInvoiceValue;
        let requestPayment = beforeTax + ppn - pph;

        let debitNoteMemo = 0;
        let partialPayment = 0;
        let creditNoteMemo = 0;

        proposal.linesInfo.purchasePayments.forEach((payment) => {
          if (payment.DebitNoteID != 0) {
            debitNoteMemo += payment.PaidInvoiceValue;
          } else {
            partialPayment += payment.PaidInvoiceValue;
          }
        });

        requestPayment =
          requestPayment - partialPayment - creditNoteMemo - debitNoteMemo;
        const tableData = {
          docNumber: proposal.PurchaseInvoiceCodeInternal,
          docDate: moment(proposal.PurchaseInvoiceCreatedAt).format(
            "DD.MM.YYYY"
          ),
          profitCenter: "Profit Center",
          ba: this.getBA(proposal.TerritoryID),
          dueDate: moment(proposal.DueDate).format("DD.MM.YYYY"),
          beforeTaxValue: this.priceFormat(beforeTax),
          ppn: this.priceFormat(ppn),
          pph: this.priceFormat(pph),
          paymentRequest: this.priceFormat(requestPayment),
        };
        totalInvoiceValue += requestPayment;
        body.push(tableData);
        const invoiceNumber = {
          docNumber: `No Inv Vendor : ${
            invoice.TaxReturnNumber ? invoice.TaxReturnNumber : "-"
          }   Keterangan : ${invoice.Description ? invoice.Description : "-"}`,
        };
        body.push(invoiceNumber);
      }

      const finalData = {
        docNumber: "Total",
        paymentRequest: this.priceFormat(totalInvoiceValue),
      };
      body.push(finalData);

      console.log("body gess", body);

      const colSpan = this.setPos(pageWidth, pageWidth, 1);
      let colWidth = 0;
      autoTable(doc, {
        startY: startY,
        columns: header,
        body: body,
        styles: {
          lineWidth: 0,
          lineColor: [0, 0, 0],
          fontSize: 12,
          minCellHeight: 0.1,
          cellPadding: 0.1,
          valign: "middle",
          halign: "center",
          font: "times",
        },
        headStyles: {
          fillColor: [219, 219, 219],
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
          fontStyle: "bold",
          valign: "middle",
          cellPadding: 0.1,
          minCellHeight: 0.1,
          font: "times",
        },
        columnStyles: {
          docNumber: { cellWidth: 2.5 },
          docDate: { cellWidth: 2 },
          profitCenter: { cellWidth: 1.5 },
          ba: { cellWidth: 1.5 },
          dueDate: { cellWidth: 2 },
          beforeTaxValue: { cellWidth: 2.5 },
          ppn: { cellWidth: 2 },
          pph: { cellWidth: 2 },
        },
        theme: "plain",
        margin: { left: 1, right: 1 },
        didParseCell(data) {
          data.cell.styles.fontSize = 9;
          const rowIndex = data.row.index;
          const columnIndex = data.column.index;
          const cellData = data.cell.raw
            ? data.cell.raw.toString()
            : "".toString();

          console.log("ini cellData", cellData);

          if (
            cellData &&
            !cellData.includes(header[columnIndex].header) &&
            !cellData.includes("No Inv Vendor") &&
            rowIndex < body.length - 1
          ) {
            if (rowIndex != rowIndexBefore) {
              proposalIndex = rowIndex / 2;
              rowIndexBefore = rowIndex;
            }

            const printCount =
              proposals[proposalIndex].linesInfo.purchasePaymentProposal
                .PrintCount;
            if (printCount > 0) {
              data.cell.styles.fillColor = [188, 188, 188];
              isGreyedOut = true;
            } else {
              data.cell.styles.fillColor = [255, 255, 255];
              isGreyedOut = false;
            }
          } else if (
            cellData &&
            !cellData.includes(header[columnIndex].header) &&
            cellData.includes("No Inv Vendor") &&
            rowIndex < body.length - 1
          ) {
            if (isGreyedOut) {
              data.cell.styles.fillColor = [188, 188, 188];
              isGreyedOut = false;
            } else {
              data.cell.styles.fillColor = [255, 255, 255];
            }
          }

          if (rowIndex === 0 && columnIndex === 0) {
            colWidth = data.cell.width;
          }

          if (rowIndex % 2 !== 0) {
            data.cell.colSpan = colSpan;
          } else if (rowIndex === body.length - 1) {
            data.cell.styles.fontStyle = "bold";
          }

          if (columnIndex <= 4) {
            return;
          }

          if (!cellData || cellData.includes(header[columnIndex].header)) {
            return;
          }
          data.cell.styles.halign = "right";
        },
        didDrawCell(data) {
          const rowIndex = data.row.index;
          const cellX = data.cell.x;
          const cellY = data.cell.y;
          if (rowIndex === body.length - 1) {
            doc.setLineWidth(0.01);
            doc.setDrawColor(0, 0, 0);
            doc.line(cellX, cellY, cellX + data.cell.width, cellY);
          }
        },
      });
      return {
        finalY: doc.lastAutoTable.finalY,
        firstColWidth: colWidth,
        totalInvoiceValue,
      };
    },
    generateNumberSaying(value) {
      return angkaTerbilang(value).toUpperCase();
    },
    async printPDF(reprint = false) {
      try {
        // let proposals = [];

        if (!reprint) {
          if (this.checkedCode.length === 0) {
            this.$vs.notify({
              color: "danger",
              title: "Print",
              text: "Please select at least one proposal to be printed",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            return;
          }

          if (!this.validateSupplier()) {
            return;
          }

          this.$vs.loading();

          this.proposals = JSON.parse(JSON.stringify(this.checkedCode));

          this.multipleReprint = this.proposals.length > 1;
          if (!this.multipleReprint) {
            const res = await this.getProposalInfo(this.proposals[0].ID);
            const data = res.data;
            this.proposals[0].linesInfo = data;
          } else {
            for (let i = 0; i < this.proposals.length; i++) {
              const res = await this.getProposalInfo(this.proposals[i].ID);
              const data = res.data;
              this.proposals[i].linesInfo = data;
            }
          }

          if (this.validateReprint(this.proposals)) {
            //validate if there is any proposal that has been printed
            this.$vs.loading.close();
            this.reprintPrompt = true;
            this.$nextTick(() => {
              const element = document.querySelector(".vs-dialog");
              element.style.width = "fit-content";
              element.style.minWitdh = "fit-content";
            });
            return;
          }
        }

        let cursorY = 0;
        const padding = 1;
        const height = 29.7;
        const width = 21;
        const pageWidth = width - padding * 2;
        const pageHeight = height - padding * 2;

        const pdf = new jsPDF({
          unit: "cm",
          format: [width, height],
        });
        console.log("props", this.proposals);

        // const company = proposals[0].linesInfo.company;
        pdf.setFontSize(12);
        pdf.setLineWidth(0.01);
        pdf.setFillColor(219, 219, 219);

        pdf.rect(
          this.setPos(pageWidth, 0, padding),
          this.setPos(pageHeight, 0, padding),
          this.setPos(pageWidth, pageWidth, padding),
          0.5,
          "FD"
        );

        cursorY += 0.4;
        pdf.setFont("times", "bold");
        pdf.text(
          "PERMOHONAN PEMBAYARAN",
          this.setPos(pageWidth, 0.5, padding),
          this.setPos(pageHeight, cursorY, padding)
        );

        pdf.text(
          "No. OGP",
          this.setPos(pageWidth, pageWidth - 6, padding),
          this.setPos(pageHeight, cursorY, padding)
        );

        cursorY += 1;
        const companyName = "PT SINARMAS DISTRIBUSI NUSANTARA";
        const companyNameWidth = pdf.getTextWidth(companyName);
        pdf.setFontSize(16);
        pdf.text(
          companyName,
          this.setPos(
            pageWidth,
            (pageWidth - 2.5 - companyNameWidth) / 2,
            padding
          ),
          this.setPos(pageHeight, cursorY, padding)
        );

        cursorY += 1;
        pdf.setFontSize(12);
        pdf.text(
          "Pembayaran Untuk / Kepada:",
          this.setPos(pageWidth, 0, padding),
          this.setPos(pageHeight, cursorY, padding)
        );

        const supplier = this.proposals[0].linesInfo.supplier;
        const supplierAddr = `(${
          supplier.Code
        }) - ${supplier.Name.toUpperCase()}, ${supplier.Address.toUpperCase()}, ${supplier.SubDistrict.toUpperCase()}, ${supplier.District.toUpperCase()}, ${supplier.City.toUpperCase()}, ${supplier.Province.toUpperCase()}`;
        const supplierAddrLine = pdf.splitTextToSize(
          supplierAddr,
          this.setPos(pageWidth, 7, padding)
        );
        cursorY += 0.5;
        pdf.setFont("times", "normal");
        pdf.text(
          this.setPos(pageWidth, 0, padding),
          this.setPos(pageHeight, cursorY, padding),
          supplierAddrLine
        );

        if (!this.multipleReprint) {
          cursorY = this.generateSingleDocumentInfo(
            pdf,
            this.proposals[0],
            cursorY,
            pageWidth
          );

          cursorY += 0.5;

          const result = this.generateSingleDocumentInvoice(
            pdf,
            this.proposals[0],
            cursorY,
            pageWidth
          );

          cursorY = result.startY;

          cursorY += 0.5;
          const totalValue = result.requestPayment;
          // const currnecy = proposals[0].linesInfo.purchaseInvoice.CurrencyCode;

          const totalValueText =
            this.generateNumberSaying(totalValue) + " RUPIAH";
          const textValueHeight = pdf.getTextDimensions(totalValueText).h;
          const totalValueTextLine = pdf.splitTextToSize(
            totalValueText,
            this.setPos(pageWidth, 15, padding)
          );
          const title = "Terbilang: ";
          const titleWidth = pdf.getTextWidth(title);

          pdf.text(
            title,
            this.setPos(pageWidth, 0, padding),
            this.setPos(pageHeight, cursorY, padding)
          );
          pdf.text(
            this.setPos(pageWidth, titleWidth + 0.2, padding),
            this.setPos(pageHeight, cursorY, padding),
            totalValueTextLine
          );

          cursorY += textValueHeight * totalValueTextLine.length;

          const description =
            this.proposals[0].linesInfo.purchaseInvoice.Description;

          cursorY += 0.15;
          pdf.text(
            `Keterangan: ${description ? description : "-"}`,
            this.setPos(pageWidth, 0, padding),
            this.setPos(pageHeight, cursorY, padding)
          );
          cursorY += 1.8;
        } else {
          cursorY -= 0.5;

          const createdDate = this.proposals[0].PurchaseInvoiceCreatedAt;
          pdf.text(
            `Tgl Dibuat : ${moment(createdDate).format("DD.MM.YYYY")}`,
            this.setPos(
              pageWidth,
              this.setPos(pageWidth, pageWidth - 8, padding),
              padding
            ),
            this.setPos(pageHeight, cursorY, padding)
          );

          const dimension = pdf.getTextDimensions(supplierAddrLine[0]);
          cursorY += dimension.h * supplierAddrLine.length + 1;

          //check wether CurrencyCode for proposal is all same
          const currencyCode =
            this.proposals[0].linesInfo.purchaseInvoice.CurrencyCode;
          console.log("Currency Code", currencyCode);
          const isSameCurrency = this.proposals.every(
            (v) => v.linesInfo.purchaseInvoice.CurrencyCode === currencyCode
          );

          if (!isSameCurrency) {
            throw new Error("Currency code is not the same");
          }

          pdf.text(
            `Currency : ${currencyCode != undefined ? currencyCode : ""}`,
            this.setPos(
              pageWidth,
              this.setPos(pageWidth, pageWidth - 8, padding),
              padding
            ),
            this.setPos(pageHeight, cursorY, padding)
          );

          cursorY += 1.3;
          const info = this.generateMultipleDocumentInvoice(
            pdf,
            this.proposals,
            cursorY,
            pageWidth
          );

          cursorY = info.finalY;
          const totalValue = info.totalInvoiceValue;
          const totalValueText =
            this.generateNumberSaying(totalValue) + " RUPIAH";
          const totalValueTextLine = pdf.splitTextToSize(
            totalValueText,
            this.setPos(pageWidth, 15, padding)
          );

          cursorY -= 0.5;
          const title = "Terbilang: ";
          const titleWidth = pdf.getTextWidth(title);
          pdf.text(
            title,
            this.setPos(pageWidth, 0.4, padding),
            this.setPos(pageHeight, cursorY, padding)
          );
          pdf.text(
            this.setPos(pageWidth, titleWidth + 0.4, padding),
            this.setPos(pageHeight, cursorY, padding),
            totalValueTextLine
          );

          const footerHeight = 9.5; //static
          if (cursorY + footerHeight + 1.8 > pageHeight) {
            pdf.addPage();
            cursorY = this.setPos(pageHeight, 0, padding);
          } else {
            const diff = pageHeight - cursorY - footerHeight;
            cursorY += diff;
          }
          // const totalValue = info.totalInvoiceValue;
          // const totalValueText = this.generateNumberSaying(totalValue);
          // const totalValueTextLine = pdf.splitTextToSize(
          //   totalValueText,
          //   this.setPos(pageWidth, 15, padding)
          // );

          // const title = "Terbilang: ";
          // const titleWidth = pdf.getTextWidth(title);
          // pdf.text(
          //   title,
          //   this.setPos(pageWidth, 0.4, padding),
          //   this.setPos(pageHeight, cursorY, padding)
          // );
          // pdf.text(
          //   this.setPos(pageWidth, titleWidth + 0.4, padding),
          //   this.setPos(pageHeight, cursorY, padding),
          //   totalValueTextLine
          // );

          // cursorY += 1.8;
        }

        this.generateFooter(pdf, cursorY, pageWidth, pageHeight);

        const pageCount = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.text(
            `Page ${i} of ${pageCount}`,
            this.setPos(pageWidth, 0, padding),
            this.setPos(height, height - 0.5, 0)
          );
        }
        // pdf.save("proposal.pdf");
        // const pdfBlob = pdf.output("blob");
        // const pdfUrl = URL.createObjectURL(pdfBlob);
        // window.open(pdfUrl, "_blank");
        this.pdfProxyClass = pdf;
        this.pdfUrlPopup = pdf.output("dataurlstring");
        this.pdfUrlPopup += "#toolbar=0";
        this.showPdf = true;

        this.$vs.loading.close();
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
        this.checked = [];
        this.checkedCode = [];
      }
      this.proposals = [];
    },
    getBA(territoryID) {
      const territory = [...this.$store.state.operatingUnit.filter.territory];
      const ter = territory.find((v) => v.TerritoryID === territoryID);
      return ter.TerritoryCode;
    },
    handleClosePopUp() {
      this.checked = [];
      this.checkedCode = [];
      this.showPdf = false;
      this.pdfUrlPopup = "";
      this.pdfProxyClass = null;
      this.proposals = [];
      this.usingCloseButton = true;
    },
    async handlePrintPDFPreview() {
      try {
        this.$vs.loading();

        const pp_ids = this.checkedCode.map((v) => v.ID);
        console.log("ID", pp_ids);
        let res = await this.updatePrintCount(pp_ids);
        if (res.code !== 200) {
          throw new Error("Failed to update print count");
        }

        res = await this.dataTable(this.params);
        if (res.code !== 200) {
          throw new Error("Failed to update print count");
        }

        console.log(this.pdfProxyClass);
        let name = "";

        if (this.checkedCode.length === 1) {
          name = `Payment Proposals_${this.checkedCode[0].PurchaseInvoiceCode}.pdf`;
        } else {
          name = "Payment Proposals.pdf";
        }
        this.pdfProxyClass.save(name);

        this.showPdf = false;
        this.pdfUrlPopup = "";
        this.pdfProxyClass = null;
        this.checked = [];
        this.checkedCode = [];
        this.usingCloseButton = true;
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        this.$vs.notify({
          color: "danger",
          title: "Print",
          text: "Failed to print",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        this.checked = [];
        this.checkedCode = [];
      }
    },
    validateSupplier() {
      // SupplierCode, SupplierName
      if (this.checked.length === 1) {
        return true;
      }
      const temp = this.checkedCode[0];
      const validate = this.checkedCode.every(
        (v) =>
          temp.SupplierCode === v.SupplierCode &&
          temp.SupplierName === v.SupplierName
      );

      if (!validate) {
        this.$vs.notify({
          color: "danger",
          title: "Print",
          text: "Please select the same supplier",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      return true;
    },
    closeCreatedPaymentCode() {
      this.showPaymentCodePopup = false;
      this.createdPaymentCodes = [];
    },
    validateReprint(proposal = []) {
      return !proposal.every(
        (v) => v.linesInfo.purchasePaymentProposal.PrintCount === 0
      );
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.highlight > td {
  background-color: #d0cdf0;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.vs-dialog {
  max-width: 1000px;
}
</style>
